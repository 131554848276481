<template>
    <div class="course">
        <div class="top-head">
            <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div>
            <div class="text-center">课程详情</div>
            <div class="edit" @click="share()"><img src="@/assets/images/course/share.png" alt=""></div>
        </div>
        <ul class="course-list courselist">
            <li>
                <img :src="domainUrl+detailData.photo" alt="">
                <div class="info">
                    <div class="title ell">{{detailData.class_name}}</div>
                    <div class="text ell">
                        <span>{{detailData.count}}讲</span>
                        {{detailData.feature}}
                    </div>
                    <div class="detail">
                        <em class="free" v-if="detailData.class_type==2">免费</em>
                        <em class="buy" v-else-if="detailData.buy==0">付费</em>
                        <em class="bought" v-else>付费</em>
                        <div class="price" v-if="detailData.buy==1&&detailData.class_type!=2">已购买</div>
                        <div class="price" v-else-if="detailData.class_type!=2">¥{{detailData.money}}</div>
                        <div class="num"  v-if="detailData.class_type!=2">{{detailData.order}}人购买</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="detail-info">
            <div class="tab">
                <span :class="{on:tabIndex==1}" @click="tabIndex=1">课程介绍</span>
                <span :class="{on:tabIndex==2}" @click="showCapoter">课程目录</span>
            </div>
            <div v-if="tabIndex==1" class="text" v-html="detailData.desc?detailData.desc:'该课程还没有详细描述呢'">
                
            </div>
            <ul class="catalog" v-if="tabIndex==2" @scroll="getNextPage" ref="class">
                <li v-for="item,index in capoterList" class="ell" @click="goDetail(item,index)">
                    <template v-if="detailData.class_type!=2 && detailData.buy!=1">
                        <span v-if="item.is_free==0">免费</span>
                        <img v-else src="@/assets/images/course/lock_red.png" alt="">
                    </template>
                    {{index+1}} {{item.name}}
                </li>
            </ul>
        </div>
        <div class="now-study" @click="payShow=true" v-if="detailData.class_type==1 && detailData.buy==0">立即购买</div>
        <div class="now-study" @click="$router.push({path:'/course/find/play',query:{id:id,index:0}})" v-else>立即学习</div>

        <!-- 支付 -->
        <pay v-if="payShow" :money="detailData.money" @close="payShow=false" @pay="pay"></pay>
        <div v-html="alipayWap" ref="alipayWap"></div>
        <!-- 推广 -->
        <share v-if="shareShow" :data="detailData" @close="shareShow=false"></share>
    </div>
</template>
<script>
import Vue from "vue";
import pay from "../common/pay";
import share from "../common/share";
export default {
    components: { pay, share },
    data() {
        return {
            shareShow: false,
            id: this.$route.query.id,
            tabIndex: 1,
            payShow: false,
            payData: {},
            detailData: {},
            alipayWap: '',
            capoterIndex: 1,
            capoterList: [],
            page: 1,
            pageNum: 1,
            require: true,
            noData: false,
            relevance_class: '',
            from: Vue.getUser().from,
            uid: Vue.getUser().uid,
            name: Vue.getUser().name,
            payType: 1,
            code: this.$route.query.code ? this.$route.query.code : '',
        }
    },
    mounted() {
        this.getDetail();
        if (this.code != "" && this.from == 2) {
            this.payType = 1;
            this.payReq();
        }
    },
    methods: {
        share() {
            if (this.from == 2) {
                this.shareShow = true
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Share.postMessage({ "nickName": this.name, "courseName": this.detailData.class_name, "courseImage": this.domainUrl + this.detailData.photo, "courseDescription": this.detailData.share_desc ? this.detailData.share_desc : this.detailData.feature, "sharedQRCodeUrl": "http://school.hailuoguniang.com/#/course/login?parent_id=" + this.detailData.id });
                } else if (isAndroid) {
                    SharedUtils.showShared(this.name, this.detailData.class_name, this.domainUrl + this.detailData.photo, this.detailData.share_desc ? this.detailData.share_desc : this.detailData.feature, "http://school.hailuoguniang.com/#/course/login?parent_id=" + this.detailData.id);
                }
            }

        },
        payReq() {
            this.$ajax.send({
                url: "/course/pay",
                data: { class_id: this.id, code: this.code, type: this.from, pay_type: this.payType, uid: this.uid },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        console.log(data.data)
                        if (this.from == 1) {
                            window.location.href = data.data.mweb_url;
                        } else {
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data.data), false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data.data));
                                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data.data));
                                }
                            } else {
                                this.onBridgeReady(data.data)
                            }
                        }

                    }
                }
            })
        },
        onBridgeReady(data) {
            this.payType = data;
            var self = this;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": data.appId,     //公众号名称，由商户传入     
                "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
                "nonceStr": data.nonceStr, //随机串     
                "package": data.package,
                "signType": data.signType,         //微信签名方式：     
                "paySign": data.sign //微信签名 
            },
                function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        self.$alert.success('支付成功！');
                        self.getDetail();
                        // self.capoterList = [];
                        // self.page = 1;
                        // self.getCapoter();
                    } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                        self.$alert.error('支付失败！');
                        self.getDetail();
                        // self.capoterList = [];
                        // self.page = 1;
                        // self.getCapoter();
                    }
                });
        },
        pay(data) {
            this.payType = data;
            var self = this;
            this.tipShow = false;
            if (this.from == 2) {
                window.location.href = "http://employerapi.hailuoguniang.com/user/new?type=1&id=" + this.id;
            } else {
                if (this.payType == 1) {
                    this.payReq();
                    setTimeout(function () {
                        this.$msgBox.show({
                            title: '温馨提示',
                            content: '请确认微信支付是否已完成',
                            confirmBtnText: '已完成支付',
                            cancelBtnText: '未完成支付',
                            isShowCancelBtn: true,
                            confirm() {
                                self.getDetail();
                                // self.capoterList = [];
                                // self.page = 1;
                                // self.getCapoter();
                            },
                            cancel() {
                                self.getDetail();
                                // self.capoterList = [];
                                // self.page = 1;
                                // self.getCapoter();
                            }
                        })
                    }, 3000);

                } else {
                    var self = this;
                    var url = "/course/pay?pay_type=2&class_id=" + this.id + "&type=" + this.from + "&uid=" + this.uid;
                    this.load(url, function (res) {
                        console.log(url)
                        self.alipayWap = res;
                        self.$nextTick(() => {
                            self.$refs.alipayWap.children[0].submit()
                        })
                    })
                }

            }
        },
        load(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    console.log(xhr.response)
                    callback(xhr.response);
                }
            }
            xhr.open('GET', url, true);
            xhr.send('');
        },
        goDetail(item, index) {
            if (item.is_free == 0 || this.detailData.class_type == 2 || this.detailData.buy == 1) {
                this.$router.push({ path: '/course/find/play', query: { id: this.id, index: index } })
            } else {
                this.payShow = true;
            }
        },
        showCapoter() {
            this.tabIndex = 2;
            if (this.capoterIndex == 1) {
                this.getCapoter();
                this.capoterIndex = 2;
            }
        },
        getNextPage() {
            let a = this.$refs.class.scrollHeight;
            let b = this.$refs.class.clientHeight;
            let c = this.$refs.class.scrollTop;
            if (b + c >= a - 50 && this.require && this.pageNum >= 20) {
                this.page++
                this.getCapoter();
            } else if (this.pageNum < 20) {
                this.isLast = true;
            }
        },
        getCapoter() {
            var self = this;
            this.require = false;
            this.$ajax.send({
                url: "/course/chapter/list",
                data: {
                    phone: Vue.getUser().uid,
                    from: Vue.getUser().from,
                    class_id: this.id,
                    page: this.page
                },
                type: "post",
                success: data => {
                    this.require = true;
                    if (!!data && data.code == "1001") {
                        this.capoterList = this.capoterList.concat(data.data.list);
                        this.pageNum = data.data.list.length;
                        this.relevance_class = data.data.relevance_class;
                    }
                }
            });
        },
        getDetail() {
            this.$ajax.send({
                url: "/course/info",
                data: { uid: Vue.getUser().uid, id: this.id },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        this.detailData = data.data;
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.course {
    height: 100vh;
}
.courselist {
    padding-top: 88px;
}
.now-study {
    background: #d51f13;
    border-radius: 8px;
    color: #fff;
    line-height: 88px;
    font-size: 32px;
    text-align: center;
    position: fixed;
    bottom: 16px;
    left: 32px;
    right: 32px;
}
.detail-info {
    border-top: 16px solid #f9f9f9;
    height: calc(100% - 466px);
    .tab {
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: space-around;
        height: 88px;
        span {
            text-align: center;
            font-size: 30px;
            width: 188px;
            line-height: 88px;
            border-bottom: 2px solid transparent;
            &.on {
                position: relative;
                border-bottom: 2px solid #d51f13;
            }
        }
    }
    .text {
        font-size: 28px;
        padding: 16px 32px;
        line-height: 42px;
        height: calc(100% - 88px);
        overflow-y: auto;
    }
    .catalog {
        padding: 16px 32px;
        height: calc(100% - 88px);
        overflow-y: auto;
        li {
            line-height: 70px;
            border-bottom: 1px solid #f9f9f9;
            font-size: 28px;
            span {
                float: right;
                padding-left: 20px;
                color: #d51f13;
                font-size: 28px;
            }
            img {
                width: 50px;
                float: right;
                padding-left: 20px;
                margin: 20px 10px 0 0;
            }
        }
    }
}
.poput-fixBottom {
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 820px;
    position: absolute;
    .title {
        border-bottom: 1px solid #f1f2f3;
        line-height: 36px;
        font-size: 36px;
        text-align: center;
        padding: 30px 32px;
        span {
            float: left;
            height: 36px;
            width: 36px;
        }
    }
    .content {
        padding: 32px 0;
        .price {
            text-align: center;
            font-size: 49px;
            line-height: 80px;
            padding: 44px 0;
            color: #353535;
            border-bottom: 1px solid #f1f2f3;
            span {
                font-size: 80px;
            }
        }
        .pay {
            padding: 32px;
            .name {
                font-size: 34px;
                line-height: 48px;
                text-align: left;
            }
            .type-list {
                margin: 56px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    flex-grow: 1;
                    text-align: center;
                    font-size: 34px;
                    line-height: 48px;
                }
            }
        }
    }
    .btn-group {
        padding: 0 32px 72px 32px;
        button {
            background: #d51f13;
            border-radius: 8px;
            width: 100%;
            height: 88px;
            color: #fff;
            font-size: 32px;
            border: none;
        }
    }
}
</style>